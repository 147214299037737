/* eslint-disable react/no-array-index-key */
/* eslint-disable func-names */
/* eslint-disable no-var */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/no-this-alias */
// @ts-nocheck
import React, { useEffect, useRef, useState, useCallback } from 'react';
import * as Yup from 'yup';

import { FormHandles, SubmitHandler } from '@unform/core';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import Modal from './Modal';
import ModalTab from '../../components/Modal';
import { Container, Content, Contact } from './styles';

import getValidationErrors from '../../utils/getValidationErrors';
import FormPayment from './FormPayment';
import img_custobeneficio from '../../assets/0-2-index/custo-beneficio-maxi-frete.png';
import img_rentabilidade from '../../assets/0-2-index/rentabilidade-maxi-frete.png';
import img_planilhas from '../../assets/0-2-index/planilhas-maxi-frete.png';
import logotipo_b from '../../assets/0-2-index/logotipo-cavik-b.png';
import img_whatsapp from '../../assets/0-2-index/whatsapp-maxi-frete-b.png';
import whatsappIcon from '../../assets/0-2-index/whatsapp-maxi-frete.png';
import emailIcon from '../../assets/0-2-index/email-maxi-frete.png';
import img_email from '../../assets/0-2-index/email-maxi-frete-b.png';
import img_facebook from '../../assets/0-2-index/facebook-maxi-frete.png';
import img_instagram from '../../assets/0-2-index/instagram-maxi-frete.png';
import img_linkedn from '../../assets/0-2-index/linkedin-maxi-frete.png';
import img_youtube from '../../assets/0-2-index/youtube-maxi-frete.png';
import logo from '../../assets/logo-mini.png';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { useAuth } from '../../hooks/auth';
import InputMask from '../../components/InputMask';
import api from '../../services/api';
import Footer from './Footer';
import {
  FcStart
} from 'react-icons/fc';

interface FormData {
  user: string;
  password: string;
}

interface Data {
  value?: string;
  name?: string;
  labelName?: string;
  isSensible?: boolean;
  id?: string;
  type?: string;
  placeholder?: string;
  dataCheckout?: string;
  options?: {
    id: string;
    text: string;
  }[];
  hidden?: boolean;
  invalidMessage?: string;
}

const Home: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const formRefForgot = useRef<FormHandles>(null);
  const formContact = useRef<FormHandles>(null);
  const [resetPassword, setResetPassword] = useState(true);
  const [message, setMessage] = useState('');
  const { signIn } = useAuth();
  const history = useHistory();

  const handleSubmit: SubmitHandler<FormData> = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          user: Yup.string()
            .required('Email obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          emailUser: data.user,
          password: data.password,
        });

        history.push('/calculos');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }
        toast.error('Ocorreu um erro ao fazer login, cheque as crendenciais.');
      }
    },
    [signIn, history],
  );

  const handleSubmitForgot: SubmitHandler = useCallback(async (data: any) => {
    try {
      await api.post('/api/v1/usuarios/forgot_password', {
        email: data.email,
      });
      toast.success(
        'As instruções para a recuperação de senha foram enviadas para o seu e-mail.',
      );
      setResetPassword(false);
    } catch (error) {
      toast.error(
        'Erro ao recuperar senha, por favor tente novamente mais tarde.',
      );
    }
  }, []);

  const handleContact: SubmitHandler = async (data): void => {
    try {
      const { nome, phone, email, company } = data;
      await api.post('/api/v1/utilities/sendmail', {
        nome,
        email,
        telefone: phone,
        empresa: company,
        mensagem: message,
      });
      toast.success('Formulário enviado com sucesso.');
    } catch (err) {
      toast.error('Ocorreu um erro ao enviar formulário.');
    }
  };

  const UserForm: React.FC = () => {
    return resetPassword ? (
      <Form ref={formRef} onSubmit={handleSubmit} className="formLogin">
        <h1>Faça o login</h1>
        <Input name="user" placeholder="Usuário" border />
        <Input name="password" type="password" placeholder="Senha" border />
        <Button type="submit">Acessar</Button>
        <button
          type="button"
          onClick={() => setResetPassword(false)}
          className="btnFooter"
        >
          Recuperar senha
        </button>
      </Form>
    ) : (
      <Form
        ref={formRefForgot}
        onSubmit={handleSubmitForgot}
        className="formLogin"
      >
        <h1>Digite seu e-mail</h1>
        <Input name="email" type="email" placeholder="Usuário" border />
        <Button type="submit">Acessar</Button>
        <button
          type="button"
          onClick={() => setResetPassword(true)}
          className="btnFooter"
        >
          Fazer Login
        </button>
      </Form>
    );
  };

  return (
    <>
      <Container>
        <Content>
          <div>
            <div className="header">
              <div className="row_1">
                <div className="row_2">
                  <div className="column_1">
                    <div className="row_4">                      
                        <img
                          src={logo}
                          width={147}
                          height={75}
                          className="image2"
                          alt="Logotipo Maxi Frete"
                        />
                    </div>
                  </div>
                  <div className="column_2">
                    <div className="row_5">
                      {/* menu */}
                      <ModalTab
                        modalContent={<UserForm />}
                        label="Área do cliente"
                        hiddenButton
                        width={50}
                        background="rgba(255,255,255, 0.4)"
                      />
                      {/* menu-final */}
                    </div>
                  </div>
                </div>
                <div className="row_8">
                  <div className="column_3">
                    <div className="row_10">
                      <div className="row_12">
                        <div className="row_15">
                          <h1 className="heading1">
                            {' '}
                            Quer calcular o custo real do seu frete?{' '}
                          </h1>
                        </div>
                      </div>
                      <div className="row_13">
                        <div className="row_16">
                          <p className="paragraph1">
                            O MaxiFrete é a melhor plataforma de precificação de
                            fretes e avaliação de rotas do país.&nbsp;Calcule os
                            fretes com precisão, de forma prática e eficaz.{' '}
                            <br />
                            Chega de prejuízos com cálculos errados.
                          </p>
                        </div>
                      </div>
                      <div className="row_14">
                        <div className="row_17">
                          <div className="column_5">
                            <div className="row_19">
                              {' '}
                              <a href="#plano" target="_self">
                                <p className="paragraph3"> Assine já </p>
                              </a>{' '}
                            </div>
                          </div>
                          <div className="column_6">
                            <div className="row_20">
                              <p className="paragraph2">
                                e ganhe o Manual do Transportador!
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column_4">
                    <div className="row_11">
                     
                     <iframe width="100%" 
                       height="315" 
                       id="videos"
                       src="https://www.youtube.com/embed/mj3T6HCkYPY" 
                       title="YouTube video player" 
                       frameborder="0" 
                       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                     </iframe>

                    </div>
                    <div style={{ textAlign:"center"}} >

                    <a  href="https://youtu.be/pqYSdEqAR1c" target="#videos">
                    <tr style={{ textAlign: 'center'}}><td> <FcStart className="paragraph44" style={{width:'40px', height:'40px'}}></FcStart></td><td style={{ verticalAlign: 'middle'}}> <p className="paragraph44">    Demonstração Maxifrete </p></td></tr>
                      </a>
                    </div>                  
                  </div>
                </div>
                <div className="row_7" />
              </div>
            </div>
            <div className="content">
              <div className="page-content">
                {' '}
                {/* InstanceBeginEditable name="page-content" */}
                <div className="row_area" id="area">
                  <div className="row_22">
                    <h2 className="heading2"> MaxiFrete </h2>
                  </div>
                </div>
                <div className="row_23">
                  <div className="row_24">
                    <p className="paragraph4">
                      Para calcular o frete é preciso olhar para diversas
                      variáveis além do combustível, do salário do motorista e
                      manutenção do veículo, é preciso levar em conta uma série
                      de outros elementos que, individualmente, são de menor
                      impacto, mas fazem muita diferença no resultado. <br />
                      <br />
                      <span
                        style={{ color: 'rgb(21, 23, 143)' }}
                        className="span1"
                      >
                        {' '}
                        O MaxiFrete é a melhor plataforma de precificação de
                        fretes e avaliação de rotas do país
                      </span>{' '}
                      e possui um ótimo custo-benefício. Com ele calcula-se os
                      fretes com precisão, de forma prática e eficaz. É um
                      sistema de fácil utilização e agiliza muito os processos
                      comerciais de cotação. É muito mais fácil e prático
                      entregar ao cliente um relatório do que uma planilha de
                      custos. O sistema realiza os cálculos de precificação,
                      cruzando diversas informações da própria transportadora,
                      completamente personalizado à realidade de cada empresa,
                      considerando seus custos fixos e variáveis, os tipos de
                      caminhões utilizados, peso transportado, etc.
                    </p>
                  </div>
                </div>
                <div className="row_25">
                  <div className="row_26">
                    <div className="column_7">
                      <div className="row_28">
                        <div className="row_31">
                          <div className="row_40">
                            <h3 className="heading3">
                              {' '}
                              Ótimo <br />
                              custo-benefício
                            </h3>
                          </div>
                        </div>
                        <div className="row_32">
                          <div className="row_41">
                            <img
                              src={img_custobeneficio}
                              width={171}
                              height={171}
                              className="image5"
                              alt="Ótimo custo-benefício Maxi Frete"
                            />
                          </div>
                        </div>
                        <div className="row_33">
                          <div className="row_42">
                            <p className="paragraph5">
                              Uma ótima relação custo-benefício através da
                              minimização dos prejuízos e dos resultados
                              eficazes.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="column_8">
                      <div className="row_29">
                        <div className="row_34">
                          <div className="row_43">
                            <h3 className="heading3">
                              {' '}
                              Avaliação da <br />
                              rentabilidade de rotas <br />
                            </h3>
                          </div>
                        </div>
                        <div className="row_35">
                          <div className="row_44">
                            <img
                              src={img_rentabilidade}
                              width={170}
                              height={171}
                              className="image6"
                              alt="Avaliação da rentabilidade de rotas Maxi Frete"
                            />
                          </div>
                        </div>
                        <div className="row_36">
                          <div className="row_45">
                            <p className="paragraph5">
                              Veja se o ciclo do caminhão, incluindo as cargas
                              de retorno, dão lucros ou prejuízos.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="column_9">
                      <div className="row_30">
                        <div className="row_37">
                          <div className="row_46">
                            <h3 className="heading3">
                              {' '}
                              Dispensa o <br />
                              uso de planilhas <br />
                            </h3>
                          </div>
                        </div>
                        <div className="row_38">
                          <div className="row_47">
                            <img
                              src={img_planilhas}
                              width={170}
                              height={171}
                              className="image7"
                              alt="Dispensa o uso de planilhas Maxi Frete"
                            />
                          </div>
                        </div>
                        <div className="row_39">
                          <div className="row_48">
                            <p className="paragraph5">
                              Faça os cálculos com agilidade, precisão e gere
                              relatórios profissionais sem perder tempo com
                              planilhas.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* InstanceEndEditable */}{' '}
              </div>
            </div>
            <div className="row_plano" id="plano">
              <div className="row_22">
                <h2 className="heading2"> Escolha seu plano abaixo: </h2>
              </div>
            </div>
            <div className="row_49">
              <div className="row_50">
                <div className="column_10">
                  <div className="row_52">
                    <div className="row_55">
                      <div className="row_70">
                        <h3 className="heading4"> Maxi I (1 usuário) </h3>
                      </div>
                    </div>
                    <div className="row_59">
                      <div className="row_71-c textModalContent">
                        <Modal
                          bg="#004970"
                          text="Mensal - R$ 189,00"
                          close
                          content={<FormPayment product={1} />}
                          title="Maxi I (1 usuário)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column_11">
                  <div className="row_53">
                    <div className="row_60">
                      <div className="row_75">
                        <h3 className="heading4"> Maxi II (2 usuários) </h3>
                      </div>
                    </div>
                    <div className="row_64">
                      <div className="row_79">
                        <div className="row_71 textModalContent">
                          <Modal
                            bg="#004970"
                            text="Mensal - R$ 253,00"
                            close
                            content={<FormPayment product={2} />}
                            title="Maxi II (2 usuários)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column_12">
                  <div className="row_54">
                    <div className="row_65">
                      <div className="row_80">
                        <h3 className="heading4"> Maxi III (3 usuários) </h3>
                      </div>
                    </div>
                    <div className="row_69">
                      <div className="row_84">
                        <div className="row_71 textModalContent">
                          <Modal
                            bg="#004970"
                            text="Mensal - R$ 342,00"
                            close
                            content={<FormPayment product={3} />}
                            title="Maxi III (3 usuários)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row_85">
              <div className="row_86">
                <div className="row_87">
                  <div className="row_91">
                    <h2 className="heading5"> MANUAL DO </h2>
                  </div>
                </div>
                <div className="row_88">
                  <div className="row_92">
                    <h3 className="heading6">TRANSPORTADOR&nbsp;</h3>
                  </div>
                </div>
                <div className="row_89">
                  <div className="row_93">
                    <p className="paragraph7">
                      Assine o Maxifrete e receba gratuitamente uma <br />
                      cópia digital do{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        Manual do Transportador
                      </span>
                      .
                    </p>
                  </div>
                </div>
                <div className="row_90">
                  <div className="row_94">
                    <p className="paragraph8">Aproveite!</p>
                  </div>
                </div>
              </div>
            </div>

            {/* InstanceEnd */}
          </div>
        </Content>
      </Container>
      <Contact>
        <h2>Entre em Contato</h2>
        <section>
          <aside>
            <p>Dúvidas</p>
            <p>Entre em contato conosco, teremos prazer em lhe ajudar.</p>
            <a href="#">
              <img src={whatsappIcon} alt="whatsappIcon" />
              (19) 98234-0511
            </a>
            <a href="#">
              <img src={emailIcon} alt="emailIcon" />
              contato@cavikconsultoria.com.br
            </a>
          </aside>
          <aside>
            <p>*campos com preenchimento obrigatório.</p>

            <Form onSubmit={handleContact} ref={formContact}>
              <Input name="nome" placeholder="Nome" />
              <Input name="email" type="email" placeholder="E-mail" />
              <Input name="phone" placeholder="Telefone" />
              <Input name="company" placeholder="Empresa" />
              <textarea
                name="message"
                id="mensagem1"
                placeholder="Mensagem"
                maxLength={500}
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
              <button type="submit">Enviar</button>
            </Form>
          </aside>
        </section>
      </Contact>
      <Footer />
    </>
  );
};

export default Home;
